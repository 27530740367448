import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

if (process.env.NODE_ENV === 'production' && !process.env.BUGSNAG_API_KEY) {
  console.error('Missing Bugsnag API key.');
}

if (process.env.BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    appVersion: process.env.RELEASE,
    notifyReleaseStages: ['production'],
    plugins: [new BugsnagPluginVue(Vue)],
  });
}

export default ({store}, inject) => {
  if (process.env.BUGSNAG_API_KEY) {
    if (process.env.RELEASE) {
      Bugsnag.addMetadata('app', 'version', process.env.RELEASE);
    }

    if (store.state.auth && store.state.auth.user) {
      let name = store.state.auth.user.name || store.state.auth.user.full_name;

      if (!name) {
        name = store.state.auth.user.first_name + ' ' + store.state.auth.user.last_name;
      }

      Bugsnag.setUser(
        store.state.auth.user.uuid || store.state.auth.user.id,
        store.state.auth.user.email,
        name,
      );
    }
  }

  inject('bugsnag', Bugsnag);
};
